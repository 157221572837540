class SelectBox {
  constructor(root) {
    this.root_ = document.createElement('div');
    this.input_ = root;
    this.options_ = [...this.input_.children];
    this.selected_ = null;
    this.isActive = false;

    // Listners
    this.onClickListener = this.handleClick.bind(this);
    this.onClickOptionListener = this.handleClickOption.bind(this);
    this.onClickDomListener = this.handleClickDom.bind(this);

    this.initDOM();
    this.registerHandlers();
  }

  initDOM() {
    // Add classes
    this.input_.classList.add('SelectBox--hide');
    this.root_.classList.add('SelectBox');

    // Wrap with main wrapper
    this.input_.parentNode.insertBefore(this.root_, this.input_);
    this.root_.appendChild(this.input_);

    // Add node for selection
    this.currentSelection_ = document.createElement('div');
    this.currentSelection_.classList.add('SelectBox__selected');
    // Insert after
    this.root_.appendChild(this.currentSelection_);

    // Handle options
    this.optionList_ = document.createElement('ul');
    this.optionList_.classList.add('SelectBox__options');
    this.options_.forEach(el => this.optionList_.appendChild(this.createOption(el.value, el.innerText, el.selected)));
    this.root_.appendChild(this.optionList_);
  }

  registerHandlers() {
    this.currentSelection_.addEventListener('click', this.onClickListener, false);
    // TODO: This is a bit hacky, maybe register/deregister it when
    // // dropdown is open instead
    document.body.addEventListener('click', this.onClickDomListener, false);
  }

  handleClick(evt) {
    evt.stopPropagation();
    this.toggleActive();
  }

  handleClickOption(evt) {
    evt.stopPropagation();
    if(this.selected_ === evt.currentTarget) {
      this.setSelected(this.optionList_.firstChild);
    } else {
      this.setSelected(evt.currentTarget);
    }

    this.toggleActive();
  }

  handleClickDom(evt) {
    if (!this.isActive) {
      return;
    }

    this.toggleActive();
  }

  toggleActive() {
    if(this.isActive) {
      this.root_.classList.remove('is-active');
      this.isActive = false;
    } else {
      this.root_.classList.add('is-active');
      this.isActive = true;
    }
  }

  setSelected(el) {
    if(this.selected_) {
      // Deselect
      this.selected_.classList.remove('is-selected');
    }

    this.selected_ = el;
    this.selected_.classList.add('is-selected');
    this.input_.value = this.selected_.rel;
    this.currentSelection_.innerText = this.selected_.innerText;

    this.emit('change', this.selected_.rel)
  }

  createOption(slug, label, selected = false) {
    const listItem = document.createElement('li');
    listItem.classList.add('SelectBox__options__item');
    listItem.innerText = label;
    listItem.rel = slug;

    if(selected) {
      this.setSelected(listItem)
    }

    // TODO: Add click handling
    listItem.addEventListener('click', this.onClickOptionListener, false);

    return listItem;
  }

  /**
   * Wrapper method to add an event listener to the component's root element. This is most useful when
   * listening for custom events.
   */
  listen(evtType, handler, options) {
    this.root_.addEventListener(evtType, handler, options);
  }

  /**
   * Wrapper method to remove an event listener to the component's root element. This is most useful when
   * unlistening for custom events.
   */
  unlisten(evtType, handler, options) {
    this.root_.removeEventListener(evtType, handler, options);
  }

  /**
   * Fires a cross-browser-compatible custom event from the component root of the given type, with the given data.
   */
  emit(evtType, evtData, shouldBubble = false) {
    let evt;
    if (typeof CustomEvent === 'function') {
      evt = new CustomEvent(evtType, {
        bubbles: shouldBubble,
        detail: evtData,
      });
    } else {
      evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(evtType, shouldBubble, false, evtData);
    }

    this.root_.dispatchEvent(evt);
  }
}

export default SelectBox;
