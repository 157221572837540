import VirtualScroll from 'virtual-scroll';

const EASE = 0.88;

const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return function() {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(function() {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export class FlyBy {
  constructor(root, options = {}) {
    this.root = root;
    this._container = this.root.firstElementChild;
    this._containerDuplicate = null;
    this._offsetY = 0;
    this._scroller = new VirtualScroll({
      el: this.root,
      mouseMultiplier: 0.5,
      touchMultiplier: 4
    });
    this._scrollDeltaY = 0;

    this.options = Object.assign({
      // PX per frame
      speed: 1
    }, options);

    // this._velocityY = this.options.speed;

    this._setDimension();
    this.registerEventHandlers();
  }

  registerEventHandlers() {
    // Handlers
    this._handleOnTick = this._onTick.bind(this);
    this._handleOnScroll = this._onScroll.bind(this);
    this._handleOnResize = this._onResize.bind(this);

    this._scroller.on(this._handleOnScroll);
    requestAnimationFrame(this._handleOnTick);

    window.addEventListener('resize', throttle(this._handleOnResize, 250));
  }

  _onResize() {
    this._setDimension();
  }

  _onScroll(evt) {
    this._scrollDeltaY = evt.deltaY;
  }

  _onTick() {
    this._scrollDeltaY = this._scrollDeltaY * EASE;
    this._offsetY += this.options.speed - this._scrollDeltaY;

    // Duplicate if needed
    if ((this._offsetY > this._minY || this._offsetY < 0) && !this._containerDuplicate) {
      this._duplicateContainer();

      // Move to top
      if(this._offsetY < 0) {
        this._offsetY = this._maxY;
      }
    }

    if (this._offsetY > this._maxY) {
      // Remove duplicate and move to bottom
      this._offsetY = 0;
      this._removeDuplicateContainer();
    } else if(this._containerDuplicate && this._offsetY < this._minY && this._offsetY > 0) {
      this._removeDuplicateContainer();
    }

    if(this._containerDuplicate) {
      this._containerDuplicate.style.transform = `translateY(${-this._offsetY}px)`;
    }

    this._container.style.transform = `translateY(${-this._offsetY}px)`;

    requestAnimationFrame(this._handleOnTick);
  }

  _flipContainers() {
    this._container = this._containerDuplicate;
    this._containerDuplicate = null;
    this.root.removeChild(this.root.firstChild);
    this._offsetY = 0;
  }

  _removeDuplicateContainer() {
    this.root.removeChild(this._containerDuplicate);
    this._containerDuplicate = null;
  }

  _duplicateContainer() {
    this._containerDuplicate = this._container.cloneNode(true);
    this.root.appendChild(this._containerDuplicate);
  }

  _setDimension() {
    const viewportHeight = this.root.offsetHeight;
    const containerHeight = this._container.offsetHeight;
    this._minY = containerHeight - viewportHeight;
    this._maxY = containerHeight;
  }
}
